import React, { useState } from "react";
import Slider from "react-slick";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import Button from "@mui/material/Button";
import "../css/gallery.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Box, IconButton, Stack, Typography } from "@mui/material";
import { Close, RemoveRedEye } from "@mui/icons-material";

const styleToDialogContent = {
  padding: "0",
  backgroundColor: "#232323",
  scrollbarColor: "#505050 #232323",
  scrollbarWidth: "thin",
  //add shadow to dialog content
  boxShadow: "0px 0px 10px 0px rgba(200,200,200,0.7)",
};

const Gallery = () => {
  const [selectedImage, setSelectedImage] = useState(null);

  const images = [
    {
      id: 3,
      src: "images/gallery-img/charity.png",
      alt: "NGO",
      link: "/sites/lovecare/index.html",
    },
    {
      id: 1,
      src: "images/gallery-img/Ghar.png",
      alt: "Real Estate",
      link: "/sites/homeline/index.html",
    },
    {
      id: 2,
      src: "images/gallery-img/FitBuddy.png",
      alt: "Fitness",
      link: "/sites/fitbuddy/index.html",
    },

    {
      id: 7,
      src: "images/gallery-img/Digital Marketing.png",
      alt: "Sales & Services",
      link: "#",
    },
    {
      id: 5,
      src: "images/gallery-img/FoodFreak.png",
      alt: "Food App",
      link: "#",
    },
    {
      id: 9,
      src: "images/gallery-img/WTF.png",
      alt: "AutoVerTest",
      link: "#",
    },
    {
      id: 8,
      src: "images/gallery-img/Mobile Design - Tutorial App.png",
      alt: "Tutorial App",
      link: "#",
    },
    {
      id: 4,
      src: "images/gallery-img/hospitals.png",
      alt: "Hospital App",
      link: "#",
    },
    {
      id: 6,
      src: "images/gallery-img/Restobar.png",
      alt: "Restaurant",
      link: "/sites/restobar/index.html",
    },
  ];

  const openModal = (image) => {
    setSelectedImage(image);
  };

  const closeModal = () => {
    setSelectedImage(null);
  };

  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    centerMode: true,
    centerPadding: "100px",
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
          centerMode: false,
          centerPadding: "0px",
        },
      },
    ],
  };

  function SampleNextArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          right: "10px",
          zIndex: "1",
          width: "40px",
          height: "40px",
          alignContent: "center",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  }

  function SamplePrevArrow(props) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{
          ...style,
          display: "block",
          background: "gray",
          left: "10px",
          zIndex: "1",
          width: "40px",
          height: "40px",
          alignContent: "center",
          borderRadius: "50%",
        }}
        onClick={onClick}
      />
    );
  }

  return (
    <div id="gallery" className="gallery-container">
      <h2 className="gallery-title">Sample Work</h2>
      <p className="tagline">Discover our exclusive range of products</p>
      <Slider {...settings} className="gallery-slider">
        {images.map((image) => (
          <div
            key={image.id}
            className="gallery-item"
            onClick={() => openModal(image)}
          >
            <img src={image.src} alt={image.alt} className="gallery-image" />
            <div className="hover-overlay">
              <div className="icon">
                <i className="ri-eye-line"></i>
              </div>
            </div>
            <div className="bottom-text">
              <p>{image.alt}</p>
            </div>
          </div>
        ))}
      </Slider>

      <Dialog
        open={!!selectedImage}
        onClose={closeModal}
        fullWidth={true}
        maxWidth="lg"
      >
        <DialogTitle>
          <Stack direction="row" spacing={2} justifyContent={"space-between"}>
            <Typography variant="h6" sx={{ fontWeight: 600 }} noWrap>
              {" "}
              {selectedImage?.alt}{" "}
            </Typography>

            <Stack direction="row" spacing={2}>
              <Button
                onClick={() => window.open(selectedImage?.link, "_blank")}
                variant="contained"
                color="success"
                size="small"
                disableElevation
                startIcon={<RemoveRedEye />}
              >
                Live
                <Box
                  sx={{
                    display: { xs: "none", md: "inline-block" },
                    marginLeft: "5px",
                  }}
                >
                  Preview
                </Box>
              </Button>
              <IconButton
                onClick={closeModal}
                size="small"
                style={{
                  borderRadius: "50%",
                  backgroundColor: "#d6d6d6",
                }}
              >
                <Close />
              </IconButton>
            </Stack>
          </Stack>
        </DialogTitle>
        <DialogContent style={{ ...styleToDialogContent }}>
          <img
            src={selectedImage?.src}
            alt={selectedImage?.alt}
            style={{ width: "100%" }}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Gallery;
