import React from 'react';
import { Link } from 'react-router-dom';
import '../css/Preview_blog.css';

function scrollToTop() {

    window.scrollTo({ top: 0, behavior: 'smooth' });

}
const Blog = () => {

    const blogPosts = [
        {
            image: '/images/blog-img/Blog_images_4.jpg',
            content: 'The Future of Computing: An Evaluation of Emerging Technologies',
            author: 'Nitish Kumar',
            date: '2023-08-06',
        },
        {
            image: '/images/blog-img/Blog-btcoin.jpg',
            content: 'Investing in Bitcoin: Strategies and Tips for Success',
            author: 'Shashank Shekhar',
            date: '2023-08-06',
        },
        {
            image: '/images/blog-img/Blog_iot.jpg',
            content: 'The Role of IoT in Smart Cities of the Future',
            author: 'Saurav Mehta',
            date: '2023-08-06',
        },
        {
            image: '/images/blog-img/Blog_imag_10.jpg',
            content: 'How to Protecting Data in the Corporate World',
            author: 'Ravi soren',
            date: '2023-08-06',
        },
        {
            image: '/images/blog-img/Blog_img-13.jpg',
            content: 'How Artificial Intelligence is Changing the World',
            author: 'Nitish Kumar',
            date: '2023-08-06',
        },
    ];
    return (
        <>
            <div id='blog' className="main-blog-container">
                <h1>Our Blogs</h1>
                <div className="blog-container">
                    <div className="banner">
                        <img src={process.env.PUBLIC_URL + blogPosts[0].image} alt="Background" />
                        <div className="post-content">
                            <p>{blogPosts[0].content}</p>
                            <a className='author'>Author:  {blogPosts[0].author}  </a>
                            <time>{blogPosts[0].date}</time>
                        </div>
                    </div>
                    <div className='post-container'>
                        {blogPosts.slice(1).map((post, index) => (
                            <div className="grid-item" key={index}>
                                <img src={process.env.PUBLIC_URL + post.image} alt="Background" />
                                <div className="post-content">
                                    <p>{post.content}</p>
                                    <a className='author'>Author:  {post.author}  </a>
                                    <time>{post.date}</time>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
                <div className="blog-button">
                    <nav>
                        <Link to="/blogs" onClick={scrollToTop}>Read More</Link>
                    </nav>
                </div>
            </div>
        </>
    );
}
export default Blog;
