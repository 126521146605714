import React, { useState, useEffect } from 'react';
import '../css/our-team.css';

const TeamMember = ({ name, role, image, socialLinks, altText, caption }) => (
    <div className="mySlides">
        <div className="member-details">
            <div id='item' className="member-name">
                <p>{name}</p>
            </div>
            <div id='item' className="img-container">
                <img src={image} alt={altText} />
            </div>
            <div id='item' className="member-desc">
                <p className="member-role">{role}</p>
                <div className="div-line"></div>
                <div className="social-handle">
                    {socialLinks.map((link, index) => (
                        <a key={index} href={link.url} target='_blank'>
                            <i className={link.icon}></i>
                        </a>
                    ))}
                </div>
            </div>
        </div>
    </div>
);

const OurTeam = () => {
    const [slideIndex, setSlideIndex] = useState(1);

    const plusSlides = (n) => {
        showSlides(slideIndex + n);
    };

    const currentSlide = (n) => {
        showSlides(n);
    };

    const showSlides = (n) => {
        const slides = document.getElementsByClassName('mySlides');
        const dots = document.getElementsByClassName('demo');

        if (n > slides.length) {
            setSlideIndex(1);
        } else if (n < 1) {
            setSlideIndex(slides.length);
        } else {
            setSlideIndex(n);
        }

        for (let i = 0; i < slides.length; i++) {
            slides[i].style.display = 'none';
        }

        for (let i = 0; i < dots.length; i++) {
            dots[i].classList.remove('showing');
        }

        slides[slideIndex - 1].style.display = 'block';
        dots[slideIndex - 1].classList.add('showing');
    };

    useEffect(() => {
        showSlides(slideIndex);
    }, [slideIndex]);

    const teamMembersData = [
        // Add your team members data here

        {
            name: 'Shashak Shekhar Bhagat',
            role: 'Backend Developer',
            image: './images/teams/shank.jpg',
            caption:'Where Pixels Meet Purpose: Crafting Experiences, One Click at a Time.',
            altText: 'member-image',
            socialLinks: [
                {
                    url: 'https://instagram.com/shank.s.chase?igshid=OGQ5ZDc2ODk2ZA==',
                    icon:'ri-linkedin-box-fill',
                },
                {
                    url: '/',
                    icon:'ri-instagram-fill',
                },
                {
                    url: 'https://www.facebook.com/shank.minz',
                    icon:'ri-facebook-circle-fill',
                },
                {
                    url: '/',
                    icon:'ri-twitter-x-fill',
                },
            ],
        },
        {
            name: 'Nitish Kumar',
            role: 'UI/UX Developer',
            image: './images/teams/nitish.jpeg',
            caption:'UI/UX Developers: Bridging the Gap Between Design and Functionality.',
            altText: 'member-image',
            socialLinks: [
                {
                    url: 'https://www.linkedin.com/in/nitish-kumar-b77197244/',
                    icon:'ri-linkedin-box-fill',
                },
                {
                    url: '/',
                    icon:'ri-instagram-fill',
                },
                {
                    url: '/',
                    icon:'ri-facebook-circle-fill',
                },
                {
                    url: '/',
                    icon:'ri-twitter-x-fill',
                },
            ],
        },
        {
            name: 'Abhishek Kumar',
            role: 'Web Developer',
            image: './images/teams/abhi-img_01.png',
            caption:'Crafting digital magic through code, as a frontend web developer.',
            altText: 'member-image',
            socialLinks: [
                {
                    url: '/',
                    icon:'ri-linkedin-box-fill',
                },
                {
                    url: '/',
                    icon:'ri-instagram-fill',
                },
                {
                    url: '/',
                    icon:'ri-facebook-circle-fill',
                },
                {
                    url: '/',
                    icon:'ri-twitter-x-fill',
                },
            ],
        },
        {
            name: 'Saurav Mehta',
            role: 'Backend Developer',
            image: './images/teams/sourav.jpeg',
            caption:'Building the Brains Behind Your Favorite Apps and Websites.',
            altText: 'member-image',
            socialLinks: [
                {
                    url: 'https://linkedin.com/in/saurav-mehta-a466ba291',
                    icon:'ri-linkedin-box-fill',
                },
                {
                    url: 'https://instagram.com/saurav_76_kr?igshid=YTQwZjQ0NmI0OA==',
                    icon:'ri-instagram-fill',
                },
                {
                    url: '/',
                    icon:'ri-facebook-circle-fill',
                },
                {
                    url: 'https://twitter.com/SauravM29028969',
                    icon:'ri-twitter-x-fill',
                },
            ]
        }
    ];

    return (
        <div id="about" className="our-team">
        <p className="our-team-content">Our Team</p>
        <p className='tagline'>Awesome Guys, Behind our community</p>
        <div className="container">

            {teamMembersData.map((member, index) => (
                <TeamMember key={index} {...member} />
            ))}

            <div className="slide-btns">
                <a className="prev" onClick={() => plusSlides(-1)}>
                <i class="ri-arrow-left-s-line"></i>
                </a>
                <a className="next" onClick={() => plusSlides(1)}>
                <i class="ri-arrow-right-s-line"></i>
                </a>
            </div>

            <div className="caption-container">
               <p id="caption">{teamMembersData[slideIndex - 1].caption}</p>
            </div>

            <div className="team-members">
                {teamMembersData.map((member, index) => (
                    <div className="member-img" key={index}>
                        <img
                            className="demo cursor"
                            src={member.image}
                            onClick={() => currentSlide(index + 1)}
                            alt={member.altText}
                        />
                    </div>
                ))}
            </div>
        </div>
        </div>
    );
};

export default OurTeam;