export default [
    {
        id: "1",
        title:
            "The Future of Computing: An Evaluation of Emerging Technologies is a compelling and informative title that can draw readers' interest in exploring the latest advancements in the world of computers.",
        author: "Nitish Kumar",
        publishedOn: "19 august 2023",
    },
    {
        id: "2",
        title:
            "Bitcoin, the pioneer of cryptocurrencies, has captured the attention of investors worldwide. With its meteoric rise and occasional volatility, it's no wonder people are keen to explore the potential rewards of Bitcoin investments ",
        author: "Shashank Shekhar",
        publishedOn: "19 august 2023",
    },
    {
        id: "3",
        title:
            "The Internet of Things (IoT) has emerged as a transformative force in the world of technology. It's a concept that has already started to reshape the way we live and work, and its potential impact is boundless. In this comprehensive guide, we'll explore what IoT is, its applications across various industries, the challenges it presents, and how to harness its power for a smarter, connected future. ",
        author: "Saurav Mehta",
        publishedOn: "19 august 2023",
    },
    {
        id: "4",
        title:
            "In today's interconnected world, cybersecurity is more critical than ever. With the constant evolution of digital threats, it's imperative to stay informed and take proactive measures to protect your digital assets. In this comprehensive guide, we'll dive into the realm of cybersecurity, covering everything from the basics to advanced strategies for safeguarding your online presence.",
        author: "Ravi soren",
        publishedOn: "19 august 2023",
    }
];
