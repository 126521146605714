import React, { useEffect } from 'react';
import '../css/Core.css';
import gsap from 'gsap';
import ScrollTrigger from 'gsap/ScrollTrigger';
import AOS from 'aos';


gsap.registerPlugin(ScrollTrigger);

const Core = () => {
  useEffect(() => {
    // Initialize AOS
    AOS.init({// Set the global offset for AOS animations to 200px below the original trigger point. This can also be used individually for each animation by adding a data-aos-offset="200" attribute to each element.
      duration: 1000, // Set the duration of the animation
      once: true, // Set to true if you want the animation to occur only once
    });

    const textElements = gsap.utils.toArray('.text');
    textElements.forEach((text) => {
      gsap.to(text, {
        backgroundSize: '100%',
        ease: 'none',
        scrollTrigger: {
          trigger: text,
          start: 'center 80%',
          end: 'center 20%',
          scrub: true,
        },
      });
    });
  }, []);

  return (
    <div className='core-values'>
      <div className='lk2'> </div>
      <div className='champion-content' data-aos="fade-right" data-aos-anchor-placement="top-center">
        <p>
          Welcome to our tech ecosystem, where innovation thrives and
          possibilities unfold. At AIKYAM, we're more than just lines of code
          we're architects of digital transformation.
        </p>
      </div>
      <h1 className='core-values-h'>Core Values</h1>
      <div className='core-content-div' data-aos='fade-left'>
      <div className='core-content'>
        <div className='core-values-left-titles1'>
          <li className='te'>
            AiKYAM कुटुम्ब
            <span className='qs'>
              <a
                className='qa'
                target='_blank'
                rel='noopener noreferrer'
              >
                ONE <br/>FAMILY
              </a>
            </span>
          </li>
        </div>
        <div className='core-values-right-content'>
          <p>
            It refers to the idea of unity, interconnectedness, and a shared
            sense of belonging among all human beings.
          </p>
        </div>
      </div>
      <div className='core-content' >
        <div className='core-values-left-titles2'>
          <li className='te'>
            AiKYAM कर्म
            <span className='qs'>
              <a
                className='qa'
               
                target='_blank'
                rel='noopener noreferrer'
              >
                Work come <br/> first
              </a>
            </span>
          </li>
        </div>
        <div className='core-values-right-content'>
          <p>
            It is a phrase that underscores the importance of prioritizing
            one's professional responsibilities and commitments.
          </p>
        </div>
      </div>
      <div className='core-content' >
        <div className='core-values-left-titles3'>
          <li className='te'>
            AiKYAM श्रेष्ठ
            <span className='qs'>
              <a
                className='qa'
                
                target='_blank'
                rel='noopener noreferrer'
              >
                Unmatched perfection
              </a>
            </span>
          </li>
        </div>
        <div className='core-values-right-content'>
          <p>
            It is also a phrase that conveys the idea of something being so
            exceptionally flawless and outstanding that it surpasses.
          </p>
        </div>
      </div>
      <div className='core-contentl'>
        <div className='core-values-left-titles4'>
          <li className='te'>
            AiKYAM सत्य
            <span className='qs'>
              <a
                className='qa'
                
                target='_blank'
                rel='noopener noreferrer'
              >
                one<br/>truth
              </a>
            </span>
          </li>
        </div>
        <div className='core-values-right-content'>
          <p>
            It is a principle that underscores the importance of openness,
            honesty, and clear communication as a means to uncover and uphold.
          </p>
        </div>
      </div>
      </div>
    </div>
  );
};

export default Core;
