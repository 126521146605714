import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faTimes } from "@fortawesome/free-solid-svg-icons";
import classnames from "classnames";
import { animateScroll as scroll } from "react-scroll";
import { NavLink } from "react-router-dom";
import "../css/navbar.css";

const Navbar = () => {
  const [scrolling, setScrolling] = useState(false);
  const [menuOpen, setMenuOpen] = useState(false);

  useEffect(() => {
    const hashFragment = window.location.hash;
    if (hashFragment) {
      const sectionId = hashFragment.substring(1);
      setTimeout(() => {
        scrollToSection(sectionId);
      }, 100);
    }
    const handleScroll = () => {
      setScrolling(window.scrollY > 50);
    };

    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const toggleMenu = () => {
    if (menuOpen) {
      document.querySelector(".navbar-nav").classList.add("close");
      setTimeout(() => {
        setMenuOpen(false);
        document.querySelector(".navbar-nav").classList.remove("close");
      }, 300);
    } else {
      setMenuOpen(true);
    }
  };

  const scrollToTop = () => {
    scroll.scrollToTop();
    setMenuOpen(false);
  };

  const scrollToSection = (sectionId) => {
    const targetSection = document.getElementById(sectionId);
    if (targetSection) {
      window.scrollTo({
        top: targetSection.offsetTop,
        behavior: "smooth",
      });
      setMenuOpen(false);
    }
  };

  const closeMenu = () => {
    setMenuOpen(false);
  };

  return (
    <nav className={classnames("navbar", { "navbar-shrink": scrolling })}>
      <div id="container">
        <NavLink
          exact
          to="/"
          className="navbar-logo-link"
          onClick={scrollToTop}
        >
          <img
            src="/images/logos/logo192.png"
            alt="Logo"
            className="navbar-logo"
          />
        </NavLink>

        <button
          className={classnames("navbar-toggle", { open: menuOpen })}
          onClick={toggleMenu}
        >
          <FontAwesomeIcon icon={menuOpen ? faTimes : faBars} />
        </button>

        <ul className={classnames("navbar-nav", { open: menuOpen })}>
          {["Home", "About Us", "Services", "Gallery", "Blogs", "Contact"].map(
            (section) => (
              <li className="nav-item" key={section}>
                <NavLink
                  exact
                  to={`/${
                    section === "Home"
                      ? ""
                      : `#${section.toLowerCase().replace(" ", "")}`
                  }`}
                  className="nav-link"
                  onClick={() => {
                    section === "Home"
                      ? scrollToTop()
                      : scrollToSection(section.toLowerCase().replace(" ", ""));
                    closeMenu();
                  }}
                >
                  {section}
                </NavLink>
              </li>
            )
          )}

          <li className="nav-item social-media-links">
            <a
              href="https://www.facebook.com"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <i className="ri-facebook-box-fill"></i>
            </a>
            <a
              href="https://www.linkedin.com"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <i className="ri-linkedin-box-fill"></i>
            </a>
            <a
              href="https://www.twitter.com"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <i className="ri-twitter-x-fill"></i>
            </a>
            <a
              href="https://www.instagram.com"
              target="_blank"
              rel="noopener noreferrer"
              className="nav-link"
            >
              <i className="ri-instagram-fill"></i>
            </a>

          </li>
        </ul>
      </div>
    </nav>
  );
};

export default Navbar;
