import React, { useEffect } from "react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import AOS from "aos";
import '../css/Footer.css';

gsap.registerPlugin(ScrollTrigger);

const Footer = () => {
    useEffect(() => {
        // Initialize AOS
        AOS.init({
            duration: 1000, // Set the duration of the animation
            once: true, // Set to true if you want the animation to occur only once
        });
    }, []);

    useEffect(() => {
        const textElements = gsap.utils.toArray('.text');
        textElements.forEach(text => {
            gsap.to(text, {
                backgroundSize: '100%',
                ease: 'none',
                scrollTrigger: {
                    trigger: text,
                    start: 'center 80%',
                    end: 'center 20%',
                    scrub: true,
                },
            });
        });
    }, []);
    return (
        <footer className="footer" >
           <div className="footter" >
                <ul className="logo-p">
                        <div class="copy">
                            <img class="logoq" src="../images/logos/logo192.png" />
                            <p className="textii"><i className="icoo-p bi-envelope-fill "></i> aikyamsolutionsindia@gmail.com</p>
                            <p className="textii"><i className="icoo-p bi-telephone-fill "></i> +91-8767794712</p>
                        </div>
                </ul>
                <ul className="social" >
                    <div>
                        <h2 className="nav__titlee">Social</h2>
                        <li className="text">Linkedin<span className="s"><a className="a" href="https://in.linkedin.com/" target="_blank" rel="noopener noreferrer">What We Want To Show</a></span></li> {/* Change <h1> to <li> */}
                        <li className="text">Instagram<span className="s"><a className="a" href="https://www.instagram.com/" target="_blank" rel="noopener noreferrer">What We Actually Do</a></span></li> {/* Change <h1> to <li> */}
                        <li className="text">Twitter<span className="s"><a className="a" href="https://twitter.com/" target="_blank" rel="noopener noreferrer">How We Release Anger</a></span></li> {/* Change <h1> to <li> */}
                    </div>
                </ul>
                <ul className="site_map">
                    <div>
                        <h2 className="nav__titlee">Site Map</h2>
                        <li className="text">Our Team<span className="s"><a className="a" target="_blank" rel="noopener noreferrer">Till It Lasts </a></span></li> {/* Change <h1> to <li> */}
                        <li className="text">What We Do<span className="s"><a className="a"  target="_blank" rel="noopener noreferrer">*Somehow Try To Do</a></span></li> {/* Change <h1> to <li> */}
                        <li className="text">Blog<span className="s"><a className="a"  target="_blank" rel="noopener noreferrer">Blah Blah Blah</a></span></li> {/* Change <h1> to <li> */}
                    </div>
                </ul>
            </div>
            <div className="container-insidee">
            <div className="circle-smalle"></div>
            <div className="circle-mediume"></div>
            <div className="circle-largee"></div>
            <div className="circle-xlargee"></div>
            <div className="circle-xxlargee"></div>
            </div>
        </footer>
    );
};
export default Footer;