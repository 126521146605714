import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Hero from './components/Hero';
import OurTeam from './components/OurTeam';
import Core from './components/Core';
import Work from './components/Work';
import Post from "./components/Post";
import Blog from "./components/Blog";
import Gallery from "./components/Gallery";
import Footer from "./components/Footer";
import Navbar from "./components/Navbar";
import ContactForm from "./components/ContactForm";
import ScrollToTop from "./components/ScrollToTop";
import "./css/App.css";

function App() {
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        // Simulating data fetching
        fetch('https://aikyam.dev/')
            .then(response => {
                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }
                return response.json();
            })
            .then(data => {
                // Process the retrieved data here
                console.log('Data from server:', data);
                setLoading(false); // Once data is loaded, set loading to false
            })
            .catch(error => {
                // Handle errors
                console.error('Error fetching data:', error);
                setLoading(false); // In case of an error, also set loading to false
            });
    }, []);
    return (
        <div className="app">
            {loading ? (
                <div className='gods'>
                    <div className="appp">
                        <div className="loader">
                            <svg viewBox="0 0 80 80">
                                <circle id="test" cx="40" cy="40" r="32"></circle>
                            </svg>
                        </div>

                        <div className="loader triangle">
                            <svg viewBox="0 0 86 80">
                                <polygon points="43 8 79 72 7 72"></polygon>
                            </svg>
                        </div>

                        <div className="loader">
                            <svg viewBox="0 0 80 80">
                                <rect x="8" y="8" width="64" height="64"></rect>
                            </svg>
                        </div>
                        <div className="loader">
                            <svg viewBox="0 0 86 80">
                                <polygon points="11 8 79 72 72"></polygon>
                                <polygon points="11 72 79 8 58"></polygon>
                            </svg>
                        </div>
                        <div class="my-progress">
                            <div class="progress-bar"></div>
                        </div>

                    </div>
                </div>
            ) : (

                <div>
                    <Router>
                        <Navbar />

                        <Routes>
                            <Route exact path='/' element={
                                <React.Fragment>
                                    <Hero />
                                    <Core />
                                    <OurTeam />
                                    <Work />
                                    <Gallery />
                                    <Blog />
                                    <ContactForm />
                                </React.Fragment>
                            } />
                            <Route path='/' element={<Blog />} />
                            <Route path='blogs' element={<Post />} />
                            <Route path='about' element={<OurTeam />} />
                            <Route path='services' element={<Work />} />
                            <Route path='contact' element={<ContactForm />} />
                        </Routes>
                        <ScrollToTop />
                        <Footer />
                    </Router>
                </div>
            )}
        </div>
    );
}

export default App;





