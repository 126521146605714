import React, { useState } from 'react';
import styles from '../css/Contact.module.css';
import '../css/ContactHeader.css';

function Form() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        message: '',
        emailStatus: '',
        isSubmitted: false,
    });

    const handleCHange = (input) => (e) => {
        const newValue = e.target.value;
        setFormData({
            ...formData,
            [input]: newValue,
        });
    };

    const submitForm = (e) => {
        e.preventDefault();

        // Set isSubmitted to true when the form is submitted
        setFormData({
            ...formData,
            isSubmitted: true,
        });

        if (formData.isSubmitted) {
            return;
        }

        const { name, email, mobile, message } = formData;

        // create a new XMLHttpRequest
        var xhr = new XMLHttpRequest();

        // get a callback when the server responds
        xhr.addEventListener('load', () => {
            // update the response state and the step
            setFormData({
                ...formData,
                emailStatus: xhr.responseText,
            });

            // Reset the fields
            setFormData({
                name: '',
                email: '',
                mobile: '',
                message: '',
                emailStatus: '',
                isSubmitted: false,
            });

            // Automatically remove the success message after 3 seconds
            setTimeout(() => {
                setFormData({
                    ...formData,
                    emailStatus: '',
                });
            }, 100); // 3000 milliseconds = 3 seconds
        });

        // open the request with the verb and the url
        xhr.open(
            'GET',
            'http://test/contactform/index.php?sendto=' +
            email +
            '&name=' +
            name +
            '&mobile=' +
            mobile +
            '&message=' +
            message
        );

        // send the request
        xhr.send();
    };

    const { name, email, mobile, message, emailStatus, isSubmitted } = formData;
 
    // Define a variable to hold the image source based on screen size
    let contactImageSrc = '/images/logos/contact2.svg'; // Default image for smaller screens

    return (
        <div id='contact'>
            <div id='contact' className="contact_section">
                <h1>CONTACT US</h1>
                <p>
                <strong>Let’s Connect:</strong>  We’re here to help, and we’d love to hear from you!
                    whether you have a question, comment, you can
                    reach out to us through the contact form of this page.{" "}

                </p>
            </div>
            <section className={styles.container}>
                <div className={styles.contact_form}>
                    <div className={styles.messageContainer}>
                        {isSubmitted && !emailStatus && name && email && mobile && message && (
                            <div className={`${styles.message} ${styles.successMessage}`}>
                                Message has been sent!
                            </div>
                        )}
                        {isSubmitted && emailStatus && (
                            <div className={`${styles.message} ${styles.successMessage}`}>
                                {emailStatus}
                            </div>
                        )}
                    </div>
                    <form onSubmit={submitForm}>
                        <div className={styles.form_control}>
                            <label htmlFor="name">
                                Name <span className={styles.required}>*</span>
                            </label>
                            <input
                                type="name"
                                name="name"
                                value={name}
                                required
                                onChange={handleCHange('name')}
                            />
                        </div>

                        <div className={styles.form_control}>
                            <label htmlFor="email">
                                Email <span className={styles.required}>*</span>
                            </label>
                            <input
                                type="email"
                                name="email"
                                value={email}
                                required
                                onChange={handleCHange('email')}
                            />
                        </div>
                        <div className={styles.form_control}>
                            <label htmlFor="mobile">
                                Mobile <span className={styles.required}></span>
                            </label>
                            <input
                                type="number"
                                name="mobile"
                                value={mobile}
                                onChange={handleCHange('mobile')}
                            />
                        </div>
                        <div className={styles.form_control}>
                            <label htmlFor="message">
                                Message <span className={styles.required}>*</span>
                            </label>
                            <textarea
                                name="message"
                                value={message}
                                required
                                onChange={handleCHange('message')}
                                rows="8"
                            />
                        </div>

                        <button type="submit" className={styles.submit_button}>
                            Submit
                        </button>
                    </form>
                </div>
                <div className={styles.contact_image}>
                    {/* Update the src attribute with the selected image source */}
                    <img src={contactImageSrc} alt="contact" />
                </div>
            </section></div>

    );
}

export default Form;
