import React from "react";
import '../css/SearchBar.css';

export default function SearchBar() {
    return (
        <div className="search-container">
            <h1 className="blog-title">BLOG</h1>
            <form action="" autoComplete="on">
                <input
                    id="search"
                    name="search"
                    type="text"
                    placeholder="What're we looking for?"
                />
                <input id="search-submit" value="Search" type="submit" />
            </form>
        </div>
    );
}
