import React from "react";
import '../css/Post.css';
import Data from "../components/Data";
import SearchBar from "../components/SearchBar";

const Products = () => {
    const blogs = Data.map((blog) => (
        <div className="postcontainer" key={blog.id}>
            <div className="Post">
                <p className="info">{blog.title}</p>
                <p className="author_publish"> Author:- {blog.author} {blog.publishedOn}</p>
            </div>
        </div>
    ));

    return (
        <>
            <SearchBar />
            {blogs}
        </>
    );
};
export default Products;

