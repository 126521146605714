import React, { useEffect } from 'react';
import '../css/Work.css'
import AOS from 'aos';

export default function Work() {
    useEffect(() => {
        // Initialize AOS
        AOS.init({
            offset: 350, // Offset (in px) from the original trigger point
            duration: 1000, // Set the duration of the animation
            once: true, // Set to true if you want the animation to occur only once
        });
    }, []);
    return (
        <div id="services" className="what-we-do">
            <div className="service" data-aos='fade-up'>
                <h1 className="service-text">SERVICE</h1>
                <div className="service-content-first-coloumn">
                    <h1 className="discover">Discover</h1>
                    <p className="para">Research</p>
                    <p className="para">Stratergy</p>
                    <p className="para">Ideation</p>
                </div>
                <div className="service-content-second-coloumn">
                    <h1 className="design">Design</h1>
                    <p className="para">Branding</p>
                    <p className="para">UX/UI</p>
                    <p className="para">SEO</p>
                </div>
                <div className="service-content-third-coloumn">
                    <h1 className="deploy">Deploy</h1>
                    <p className="para">Development</p>
                    <p className="para">Prototyping</p>
                    <p className="para">Testing</p>
                </div>
            </div>
            <h1 className="experience" data-aos='fade-down'>
                OUR EXPERIENCE
            </h1>
            <p className="working-brand" data-aos='fade-down'>
                Technologies we've worked with along the way.
            </p>
            <br /><br />
            <div className="logo-icons-first-line">
                <div className="html-logo" data-aos='fade-down'><img src="./images/exper-icon/html2.png" className="html2" alt="Loading" /></div>
                <div className="css-logo" data-aos='fade-down'><img src="./images/exper-icon/css1.png" className="css1" alt="Loading" />  </div>
                <div className="php-logo" data-aos='fade-down'><img src="./images/exper-icon/php.png" className="php" alt="Loading" /></div>
                <div className="js-logo" data-aos='fade-down'><img src="./images/exper-icon/node.png" className="js" alt="Loading" /></div>
                <div className="react-logo" data-aos='fade-down'><img src="./images/exper-icon/react.png" className="react" alt="Loading" /></div>
            </div>
            <div className="logo-icons-second-line">
                <div className="boot-logo" data-aos='fade-up'><img src="./images/exper-icon/boot.png" className="boot" alt="Loading" /></div>
                <div className="sql-logo" data-aos='fade-up'><img src="./images/exper-icon/sql.png" className="sql" alt="Loading" /></div>
                <div className="firebase-logo" data-aos='fade-up'><img src="./images/exper-icon/firebase.png" className="firebase" alt="Loading" /></div>
                <div className="mongo-logo" data-aos='fade-up'><img src="./images/exper-icon/mongo.png" className="mongo" alt="Loading" /></div>
                <div className="flutter-logo" data-aos='fade-up'><img src="./images/exper-icon/flutter.png" className="flutter" alt="Loading" /></div>
            </div>
            <br></br> <br></br><br></br>
        </div>
    )
}