//Hero.jsx
import React, { useEffect } from "react";
import AOS from 'aos';
import Robot from './Robot';
import "../css/Hero.css";


export default function Hero() {
    useEffect(() => {
        // Initialize AOS
        AOS.init({
            offset: 350, // Offset (in px) from the original trigger point
            duration: 1000, // Set the duration of the animation
            once: true, // Set to true if you want the animation to occur only once
        }); 
    }, []);
    return (
        <main id='#home' className="whole">
            <div className="main--title" data-aos='fade-right'>
                <h1 className="main-title-h">transforming ideas to <br /> reality</h1>
                <p className="main-p">We are team of passionate designers and developers making websites,<br /> apps and stuffs</p>

                <div className="butt" >
                    <button class="ui-btn">
                      <a href="#services">Work_Catalogue</a>
                    </button>
                    <button class="learn-more">
                        <span class="circle" aria-hidden="true">
                            <span class="icon arrow"></span>
                        </span>
                        <a href="#contact" class="button-text">Get Connected</a>
                    </button>
                </div>
                
            </div>

            <div className="blob" data-aos='fade-left' data-aos-duration="2000">
                <div className="shape-blob three"></div>
            </div>
            <div className="container-inside" data-aos='fade-down-right' data-aos-duration="3000">
                <div className="circle-small" ></div>
                <div className="circle-medium"></div>
                <div className="circle-large"></div>
                <div className="circle-xlarge"></div>
                <div className="circle-xxlarge"></div>
            </div>
            <div className="Robot" data-aos='fade-left' data-aos-duration="2000">
                <Robot />
            </div>
        </main>
    );
}
